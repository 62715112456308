var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "store-detail-container" }, [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "header-logo" }, [
        _c("img", { attrs: { src: _vm.logo } }),
      ]),
      _c("div", { staticClass: "header-info" }, [
        _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.name))]),
        _c("div", { staticClass: "tag" }),
      ]),
    ]),
    _vm._m(0),
    _c("div", {
      staticClass: "description-content",
      domProps: { innerHTML: _vm._s(_vm.desc) },
    }),
    _vm._m(1),
    _c(
      "div",
      { staticClass: "product-content" },
      _vm._l(_vm.productList, function (item) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "product-item",
            on: {
              click: function ($event) {
                return _vm.storeDetali(item.id)
              },
            },
          },
          [
            _c("div", { staticClass: "product-item-in" }, [
              _c("div", { staticClass: "cover" }, [
                _c("img", { attrs: { src: item.picUrl } }),
              ]),
              _c("div", { staticClass: "name" }, [
                _vm._v(_vm._s(item.productName)),
              ]),
              _c("div", { staticClass: "spec" }, [
                _vm._v(_vm._s(item.productSpec)),
              ]),
              _c("div", { staticClass: "price" }, [
                _c("span", { staticClass: "signal" }, [_vm._v("￥")]),
                _vm._v(_vm._s(item.price) + " "),
              ]),
            ]),
          ]
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "pager" },
      [
        _c("el-pagination", {
          attrs: {
            background: "",
            layout: "prev, pager, next",
            total: _vm.page.total,
            "page-size": _vm.page.pageSize,
            "current-page": _vm.page.currentPage,
          },
          on: { "current-change": _vm.handleCurrentChange },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-box" }, [
      _c("div", { staticClass: "title-box-left" }, [
        _c("div", { staticClass: "icon" }),
        _c("div", { staticClass: "title" }, [_vm._v("店铺简介")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-box" }, [
      _c("div", { staticClass: "title-box-left" }, [
        _c("div", { staticClass: "icon" }),
        _c("div", { staticClass: "title" }, [_vm._v("主要产品")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }