import { render, staticRenderFns } from "./storeDetail.vue?vue&type=template&id=4c097483&scoped=true&"
import script from "./storeDetail.vue?vue&type=script&lang=js&"
export * from "./storeDetail.vue?vue&type=script&lang=js&"
import style0 from "./storeDetail.vue?vue&type=style&index=0&id=4c097483&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c097483",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\xian\\work\\datacockpit\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4c097483')) {
      api.createRecord('4c097483', component.options)
    } else {
      api.reload('4c097483', component.options)
    }
    module.hot.accept("./storeDetail.vue?vue&type=template&id=4c097483&scoped=true&", function () {
      api.rerender('4c097483', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/Mall/components/storeDetail.vue"
export default component.exports