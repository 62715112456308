<template>
    <div class="store-detail-container">
        <div class="header">
            <div class="header-logo">
                <img :src="logo">
            </div>
            <div class="header-info">
                <div class="title">{{name}}</div>
                <div class="tag">
                    <!-- <img src="../image/huiyuan.png"> -->
                    <!-- <img src="../image/renzheng.png"> -->
                    <!-- <img src="../image/you.png"> -->
                </div>
            </div>
        </div>

        <div class="title-box">
            <div class="title-box-left">
                <div class="icon"></div>
                <div class="title">店铺简介</div>
            </div>
        </div>

        <div class="description-content" v-html="desc"></div>

        <div class="title-box">
            <div class="title-box-left">
                <div class="icon"></div>
                <div class="title">主要产品</div>
            </div>
        </div>

        <div class="product-content">
            <div class="product-item"
                v-for="item in productList"
                :key="item.id" v-on:click="storeDetali(item.id)">
                <div class="product-item-in" >
                    <div class="cover">
                        <img :src="item.picUrl">
                    </div>
                    <div class="name">{{item.productName}}</div>
                    <div class="spec">{{item.productSpec}}</div>
                    <div class="price">
                        <span class="signal">￥</span>{{item.price}}
                    </div>
                </div>
            </div>
        </div>

        <!-- 分页器 -->
        <div class="pager">
            <el-pagination
                background
                layout="prev, pager, next"
                :total="page.total"
                :page-size="page.pageSize"
                :current-page="page.currentPage"
                @current-change="handleCurrentChange"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import {selectByClientId,selectBrandById}from "../api";
    export default {
        created() {
            var _this=this;
            var brand_id=0;
            let clientId = this.$route.query.clientId;
            selectByClientId({
                clientId:clientId,
                pageSize: _this.page.pageSize,
                pageNo: 1
            }).then(
                res =>{
                    _this.productList=undefined;
                    _this.productList=new Array();
                    brand_id=res.data.list[0].brandId;
                    for (var v in res.data.list){
                        _this.productList.push({
                            "id":res.data.list[v].id,
                            "productName":res.data.list[v].name,
                            "picUrl":res.data.list[v].picUrl,
                            "price":res.data.list[v].retailPrice,
                            "productSpec":res.data.list[v].material,
                        })
                    }
                    _this.page.total = res.data.total;
                    selectBrandById({id:brand_id}).then(
                        res =>{
                        console.log(res)
                            _this.desc=res.data.desc;
                            _this.name=res.data.name;
                            _this.logo=res.data.picUrl;
                        }
                    )
                }
            )
        },
        data() {
            return {
                desc:'',
                name:'',
                logo:"",
                productList: [],
                page: {
                    currentPage: 1,
                    total: 100,
                    pageSize: 10
                },
            }
        },
        methods: {
            storeDetali(id){
                var _this=this;
                _this.$router.push({
                    path: '/home/goodsDetails?id=' + id
                });
            },
            // 触发分页器
            handleCurrentChange(val) {
                var _this = this;
                this.page.currentPage = val;

                selectByClientId({
                    clientId: _this.$route.query.clientId,
                    pageSize: _this.page.pageSize,
                    pageNo: val
                }).then(
                    res =>{
                        _this.productList = [];
                        for (let v in res.data.list){
                            _this.productList.push({
                                "id":res.data.list[v].id,
                                "productName":res.data.list[v].name,
                                "picUrl":res.data.list[v].picUrl,
                                "price":res.data.list[v].retailPrice,
                                "productSpec":res.data.list[v].material,
                            });
                        }
                        _this.page.total = res.data.total;
                    }
                )
            }
        }
    }
</script>

<style lang="less" scoped>
.store-detail-container {
    width: 1200px;
    margin: 0 auto;

    .header {
        display: flex;
        margin-top: 10px;
        padding: 10px 20px ;
        box-sizing: border-box;
        background: #fff;
        &-logo {
            width: 72px;
            height: 72px;

            img {
                width: 100%;
                height: 100%;
            }
        }
        &-info {
            flex: 1;
            margin-left: 19px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .title {
                font-size: 24px;
                color: #333333;
                font-weight: bold;
            }
            .tag {
                img {
                    margin-right: 6px;
                    height: 19px;
                }
            }
        }
    }
    .title-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 28px 0 21px;
        margin-top: 24px;

        &-left {
            display: flex;
            align-items: center;

            .icon {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: skyblue;
            }
            .title {
                margin-left: 12px;
                font-size: 22px;
                color: #333333;
                font-weight: bold;
            }
        }
        &-right {
            font-size: 14px;
            color: #2ca2c6;
            cursor: pointer;
        }
    }
    .product-content {
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        margin: 0 -8px;

        .product-item {
            width: calc(20% - 16px);
            padding: 0 8px;
            margin-bottom: 16px;

            .product-item-in {
                width: 100%;
                font-size: 14px;
                color: #333333;
                font-weight: bold;
                cursor: pointer;
                background: #fff;
                padding: 18px;
                box-sizing: border-box;

                &:nth-of-type(5n) {
                    margin-right: 0;
                }
                .name {
                    font-size: 13px;
                }
                .cover {
                    width: 100%;
                    height: 184px;
                    overflow: hidden;
                    margin-bottom: 10px;

                    img {
                        width: 100%;
                    }
                }
                .price {
                    margin-top: 8px;
                    font-size: 18px;
                    color: #ff4400;
                    line-height: 1;
                }
            }
        }
    }
    .description-content {
        padding: 0 24px;
        box-sizing: border-box;
        text-indent: 2em;
        font-size: 12px;
        color: #333333;
        line-height: 24px;
    }
    .pager {
        margin-top: 20px;
        margin-bottom: 50px;
        text-align: right;
    }
}
</style>
